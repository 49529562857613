import { FC } from 'react';
import Subscribe from './Subscribe';
import { StripeProvider } from '../contexts/StripeProvider';

const Payment: FC = () => {
  return (
    <StripeProvider>
      <Subscribe />
    </StripeProvider>
  );
};

export default Payment;
