import React, { useRef, useEffect } from 'react';
import * as monaco from 'monaco-editor';
import '../../../styles/MonacoEditor.css';
import { useProject } from '../../../contexts/ProjectContext';

const CodeEditor: React.FC = () => {
  const editorRef = useRef<HTMLDivElement | null>(null);
  const editorInstance = useRef<monaco.editor.IStandaloneCodeEditor | null>(
    null
  );

  const { showEditorData, setEditorInstance } = useProject();

  useEffect(() => {
    if (editorRef.current) {
      const editor = monaco.editor.create(editorRef.current, {
        value: '', // Initialize with empty content
        language: 'javascript',
        theme: 'vs-dark',
        automaticLayout: true,
      });

      editorInstance.current = editor;
      setEditorInstance(editor);

      // Set initial editor content
      if (showEditorData) {
        editor.setValue(showEditorData);
      } else {
        editor.setValue('// Write Code');
      }

      // // Expose the editor's content getter directly
      // Object.defineProperty(window, 'editorContentGetter', {
      //   get: () => editor.getValue(),
      // });

      // Handle layout adjustments on window resize
      const handleResize = () => editor.layout();
      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
        editor.dispose();
      };
    }
  }, [showEditorData]);

  return (
    <div
      className="Monaco_Editor_Outer_Wrapper"
      data-testid="MonacoEditor-comp"
    >
      <div ref={editorRef} className="Monaco_Editor_Main_Container1" />
    </div>
  );
};

export default CodeEditor;
