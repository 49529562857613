import { Routes, Route, BrowserRouter } from 'react-router-dom';
import React from 'react';
// import Login from "./components/login/Login";
import App from './App';
import ProtectedRoutes from './generic components/ProtectedRoutes';
import { SessionProvider } from './contexts/SessionContext';
import LandingPageMain from './components/landingPage/LandingPageMain';
import { ProjectProvider } from './contexts/ProjectContext';
import Payment from './screens/Payment';

export default function Router() {
  return (
    <div>
      <BrowserRouter>
        <SessionProvider>
          <Routes>
            {/* <Route path="/login" element={<Login />} /> */}
            <Route
              path="/app"
              element={
                <ProtectedRoutes>
                  <ProjectProvider>
                    <App />
                  </ProjectProvider>
                </ProtectedRoutes>
              }
            />
            <Route path="/" element={<LandingPageMain />} />
            <Route path="/payment" element={<Payment />} />
            <Route path="*" element={<h1>404 Not Found</h1>} />
          </Routes>
        </SessionProvider>
      </BrowserRouter>
    </div>
  );
}
