import React, { useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { useSession } from '../contexts/SessionContext';
import '../styles/PaymentForm.css'; // Import the CSS file

export interface PaymentFormProps {
  subscriptionTypeId: string;
  renewalInterval: string;
}

const elementsOptions = {
  style: {
    base: {
      color: '#f8f8f2',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSize: '16px',
      '::placeholder': {
        color: '#a8a8a8',
      },
      ':focus': {
        color: '#ffffff',
        backgroundColor: 'rgba(102, 0, 255, 0.5)',
      },
    },
    invalid: {
      color: '#ff6b6b',
    },
  },
};

const PaymentForm: React.FC<PaymentFormProps> = ({
  subscriptionTypeId,
  renewalInterval,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const { apiKey } = useSession();

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return; // Stripe.js has not yet loaded.
    }

    const cardElement = elements.getElement(CardElement);

    if (!cardElement) {
      return;
    }

    setLoading(true);

    try {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      });

      if (error) {
        setError(error.message || 'An error occurred');
        setLoading(false);
        return;
      }

      const response = await fetch('/subscription/', {
        // TODO: Needs to create an accout and inject the API key first.
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-API-Key': apiKey,
        },
        body: JSON.stringify({
          payment_method_id: paymentMethod?.id,
          subscription_type_id: subscriptionTypeId,
          renewal_interval: renewalInterval,
        }),
      });

      const result = await response.json();

      if (response.ok) {
        navigate('/thank-you');
      } else {
        setError(result.error || 'An unexpected error occurred');
      }
    } catch (e) {
      setError('An unexpected error occurred. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <form className="payment-form" onSubmit={handleSubmit}>
      <div className="card-element-container">
        <CardElement options={elementsOptions} />
      </div>
      {error && <div className="error-message">{error}</div>}
      <Button className="submit-button" type="submit" disabled={loading}>
        {loading ? 'Processing...' : 'Subscribe'}
      </Button>
    </form>
  );
};

export default PaymentForm;
