import { BASE_URL } from '../config/config';

// Base API request
export const apiRequest = async <T>(
  endpoint: string,
  options: RequestInit,
  apiKey: string,
  sessionId: string
): Promise<T> => {
  // Construct the full URL using the URL class
  const url = new URL(endpoint, BASE_URL);

  // Ensure headers are set correctly
  const headers: HeadersInit = {
    ...(options.headers || {}),
    'X-API-Key': apiKey,
    'X-Session-ID': sessionId,
  };

  const requestOptions: RequestInit = {
    ...options,
    headers,
  };

  try {
    console.debug('Final API request URL:', url.toString());
    console.debug('Final API request options:', requestOptions);

    const response = await fetch(url.toString(), requestOptions);

    if (!response.ok) {
      const errorText = await response.text();
      console.error(
        'API request failed:',
        response.status,
        response.statusText,
        errorText
      );
      throw new Error(
        `Error (HTTP ${response.status}): ${response.statusText}`
      );
    }

    return (await response.json()) as T;
  } catch (error) {
    console.error('Request failed:', error);
    throw error;
  }
};

// JSON-based requests
export const handleApiRequest = async <T>(
  endpoint: string,
  method: 'GET' | 'POST' | 'PUT' | 'DELETE',
  body: Record<string, any> | null,
  apiKey: string | null,
  sessionId: string | null
): Promise<T | undefined> => {
  if (!apiKey || !sessionId) {
    console.warn('API key or Session ID missing');
    return undefined;
  }

  const options: RequestInit = {
    method,
    headers: {
      'Content-Type': 'application/json',
    },
    body: body ? JSON.stringify(body) : null,
  };

  try {
    return await apiRequest<T>(endpoint, options, apiKey, sessionId);
  } catch (error) {
    console.error('API request failed:', error);
    throw error;
  }
};

// Raw text requests
export const handleRawRequest = async <T>(
  endpoint: string,
  method: 'POST' | 'PUT',
  body: string | null,
  apiKey: string | null,
  sessionId: string | null
): Promise<T | undefined> => {
  if (!apiKey || !sessionId) {
    console.warn('API key or Session ID missing');
    return undefined;
  }

  const options: RequestInit = {
    method,
    headers: {
      'Content-Type': 'text/plain; charset=utf-8',
    },
    body,
  };

  try {
    return await apiRequest<T>(endpoint, options, apiKey, sessionId);
  } catch (error) {
    console.error('API request failed:', error);
    throw error;
  }
};

// Fetch file content as plain text
export const fetchFileContent = async (
  endpoint: string,
  apiKey: string,
  sessionId: string
): Promise<string> => {
  return await apiRequest<string>(
    endpoint,
    {
      method: 'GET',
    },
    apiKey,
    sessionId
  );
};

// File request handler (RESTORED)
export const handleFileRequest = async (
  endpoint: string,
  apiKey: string | null,
  sessionId: string | null
): Promise<string | undefined> => {
  if (!apiKey || !sessionId) {
    console.warn('API key or Session ID missing');
    return undefined;
  }
  try {
    return await fetchFileContent(endpoint, apiKey, sessionId);
  } catch (error) {
    console.error('File fetch failed:', error);
    throw error;
  }
};
