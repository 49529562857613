import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  ReactNode,
} from 'react';
import {
  handleApiRequest,
  handleFileRequest,
  handleRawRequest,
} from '../utils/apiUtils';
import { useSession } from './SessionContext';
import { Project, FolderData } from '../models/Project';
import { webSocketClient } from '../ws/WebSocketClient';
import { InvalidateFilesystemMessage } from '../ws/messages/InvalidateFilesystemMessage';
import * as monaco from 'monaco-editor';

interface ProjectContextProps {
  projects: Project[];
  projectId: string | null;
  setProjectId: (id: string | null) => void;
  projectName: string | null;
  folderData: FolderData | null;
  showEditorData: string | null;
  setShowEditorData: (data: string | null) => void;
  createProject: boolean;
  setCreateProject: (value: boolean) => void;
  newProjectCreated: (projectId: string) => void;
  isDirty: boolean;
  setDirty: (dirtyState: boolean) => void;
  filesWritten: string[] | null;
  getFileList: () => Promise<void>;
  getFile: (filePath: string) => Promise<void>;
  saveFile: (filePath: string) => Promise<void>;
  deleteFile: (filePath: string) => Promise<void>;
  deleteProject: (projectId: string) => Promise<void>;
  selectedFilePath: string | null;
  setSelectedFilePath: (path: string | null) => void;
  refreshProjects: () => Promise<void>;
  editorInstance: monaco.editor.IStandaloneCodeEditor | null;
  setEditorInstance: (
    editor: monaco.editor.IStandaloneCodeEditor | null
  ) => void;
}

const ProjectContext = createContext<ProjectContextProps | undefined>(
  undefined
);

export const ProjectProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  console.debug('Initializing ProjectProvider');
  const { apiKey, sessionId } = useSession();
  const [projects, setProjects] = useState<Project[]>([]);
  const [projectId, setProjectId] = useState<string | null>(null);
  const [folderData, setFolderData] = useState<FolderData | null>(null);
  const [showEditorData, setShowEditorData] = useState<string | null>(null);
  const [createProject, setCreateProject] = useState<boolean>(false);
  const [isDirty, setDirty] = useState<boolean>(false);
  const [filesWritten, setFilesWritten] = useState<string[] | null>(null);
  const [selectedFilePath, setSelectedFilePath] = useState<string | null>(null);
  const [editorInstance, setEditorInstance] =
    useState<monaco.editor.IStandaloneCodeEditor | null>(null);

  const projectName = projectId
    ? projects.find((project) => project.id === projectId)
        ?.human_readable_name ||
      projects.find((project) => project.id === projectId)?.dir_name ||
      null
    : null;

  useEffect(() => {
    const handleInvalidation = (message: InvalidateFilesystemMessage) => {
      if (message.project_id === projectId) {
        console.log(
          `ProjectContext: Handling invalidation: files modified: ${message.files_written}`
        );
        setDirty(true);
        setFilesWritten(message.files_written || []); // Update only within context
      }
    };
    webSocketClient.subscribeToFilesystemMessages(handleInvalidation);
    return () => {
      webSocketClient.unsubscribeFromFilesystemMessages(handleInvalidation);
    };
  }, [projectId]);

  const getFileList = async () => {
    if (!projectId) return;
    try {
      const data = await handleApiRequest<Record<string, any>>(
        `/project/${projectId}/`,
        'GET',
        null,
        apiKey,
        sessionId
      );
      setFolderData(data as FolderData);
      console.debug('File list fetched:', data);
    } catch (error) {
      console.debug('Clearing folderData');
      setFolderData(null);
      console.error('Error fetching file list:', error);
    }
  };

  const getFile = async (filePath: string) => {
    if (!projectId) return;
    console.debug(`Fetching file /project/${projectId}/${filePath}`);
    try {
      const data = await handleFileRequest(
        `/project/${projectId}/${filePath}`,
        apiKey,
        sessionId
      );
      setShowEditorData(data || null);
    } catch (error) {
      console.error('Error fetching file:', error);
    }
  };

  const saveFile = async (filePath: string) => {
    if (!projectId) return;
    if (!editorInstance) return;
    const content = editorInstance.getValue();
    console.debug(
      `Saving file /project/${projectId}/${filePath} with content:\n${content}`
    );
    try {
      await handleRawRequest(
        `/project/${projectId}/${filePath}?force=true`,
        'PUT',
        content,
        apiKey,
        sessionId
      );
      console.debug(`File ${filePath} saved.`);
    } catch (error) {
      console.error('Error saving file:', error);
    }
  };

  const deleteFile = async (filePath: string) => {
    if (!projectId) return;
    try {
      await handleApiRequest(
        `/project/${projectId}/${filePath}`,
        'DELETE',
        null,
        apiKey,
        sessionId
      );
      console.debug(`File ${filePath} deleted.`);
    } catch (error) {
      console.error('Error deleting file:', error);
    }
  };

  const deleteProject = async (projectId: string) => {
    try {
      await handleApiRequest(
        `/project/${projectId}`,
        'DELETE',
        null,
        apiKey,
        sessionId
      );
      console.debug(`Project ${projectId} deleted.`);
      setProjectId(null);
      await refreshProjects();
    } catch (error) {
      console.error('Error deleting project:', error);
    }
  };

  const newProjectCreated = async (projectId: string) => {
    await refreshProjects();
    setProjectId(projectId);
  };

  const refreshProjects = async () => {
    try {
      const data = await handleApiRequest<{ projects: Project[] }>(
        '/project',
        'GET',
        null,
        apiKey,
        sessionId
      );
      setProjects(data?.projects || []); // Access data.projects
    } catch (error) {
      console.error('Error refreshing projects:', error);
      setProjects([]); // Clear projects on error
    }
  };

  useEffect(() => {
    refreshProjects();
  }, [apiKey, sessionId]);

  useEffect(() => {
    if (projectId) {
      getFileList();
    } else {
      setFolderData(null); // Clear folder data when no project is selected
      console.debug('Cleared folderData as projectId is null');
    }
  }, [projectId]);

  return (
    <ProjectContext.Provider
      value={{
        projects,
        projectId,
        setProjectId,
        projectName,
        folderData,
        showEditorData,
        setShowEditorData,
        createProject,
        setCreateProject,
        newProjectCreated,
        isDirty,
        setDirty,
        filesWritten,
        getFileList,
        getFile,
        saveFile,
        deleteFile,
        deleteProject,
        selectedFilePath,
        setSelectedFilePath,
        editorInstance,
        setEditorInstance,
        refreshProjects,
      }}
    >
      {children}
    </ProjectContext.Provider>
  );
};

export const useProject = (): ProjectContextProps => {
  const context = useContext(ProjectContext);
  if (!context) {
    throw new Error('useProject must be used within a ProjectProvider');
  }
  return context;
};
